import React from "react";

export default function Faq() {
    return (
        <main>
            <div className="container page mb-5">
                <h1>FAQ</h1>
                <p><strong>What is an eSIM?</strong></p>
                <p>An eSIM is an embedded SIM card available on eSIM capable devices. It provides a smarter and more
                    convenient way to stay connected by allowing you to download data packs directly to your phone. No
                    more scrambling for SIM cards. No more worrying about losing your original SIM card!</p>
                <p>&nbsp;</p>
                <p><strong>How do I install and set up an eSIM on my device with QR code? </strong></p>
                <p>Please check that your device is eSIM compatible and network unlocked. After your payment is proceed
                    you will immediately receive a QR code. If you want to install eSIM on this phone, send the QR code
                    to another phone or computer. Then scan it with this phone. Scanning the code only once is possible!
                    Then follow the instructions in the phone settings. </p><p>After installing your eSIM, you must also
                make the necessary adjustments for the connection.
            </p>
                <ol>
                    <li>On your iOS device, go to Settings &gt; Cellular or Settings&gt; Mobile.</li>
                    <li>Tap on your newly installed eSIM plan.</li>
                    <li>Toggle 'Turn on this line' for your eSIM and enable the&nbsp;'Data Roaming'</li>
                </ol>
                <p>&nbsp;</p>
                <p><b>How do I install and set up an eSIM on my device manually?</b></p><p> In some cases, your may have
                to set up your eSIM manually. </p><p>

                For iOS devices
            </p><p style={{marginLeft: "25px"}}>Go to Settings &gt; Cellular &gt; Add Cellular Plan &gt; Enter Details
                Manually
                Enter the SM-DP and Activation code that you received in the activation message with QR code.
            </p><p>

                For Android devices
            </p><p style={{marginLeft: "25px"}}>
                Go to Settings &gt; Network and Internet &gt; Mobile Network &gt; Add &gt; Download SIM card &gt; Need
                help

                In the “Enter manually” section, paste the Activation code that you received in the activation message
                with QR code.</p>
                <p>&nbsp;</p>
                <p><strong>How can I top up an eSIM? </strong></p>
                <p>The eSIM is non-rechargeable. If the data package is used up or has expired, please purchase a new
                    eSIM.</p>
                <p>&nbsp;</p>
                <p><strong>Can I make phone calls or send SMS with my eSIM?</strong></p>
                <p>You will not be able to make phone calls or send SMS text messages, as our eSIM packs provide data
                    only. However, you can use internet-based calls and messages</p>
                <p>&nbsp;</p>
                <p><strong>Can I reinstall an eSIM?</strong></p>
                <p>Most eSIMs cannot be reinstalled, which means that if you remove your eSIM you may encounter an error
                    when trying to download it again.</p>
                <p>&nbsp;</p>
                <p><strong>Can I install the same eSIM on multiple devices?</strong></p>
                <p>No, you cannot install the same eSIM on multiple devices. Your eSIM can only be installed on one
                    device, and most eSIMs can be installed only once. This means that if you remove the eSIM from your
                    device, it will not be possible to install it again.</p>
                <p>&nbsp;</p>
                <p><strong><a name="actual_devices">What devices support eSIM?</a></strong></p>
                <p>The list is under constant expansion as more eSIM-compatible devices get released into the market.
                    Please ensure that your device is eSIM-compatible and carrier unlocked before purchasing.</p>
                <p>Apple</p>
                <ul>
                    <li>iPhone 13</li>
                    <li>iPhone 13 Pro</li>
                    <li>iPhone 13 Pro Max</li>
                    <li>iPhone 13 Mini</li>
                    <li>iPhone 12</li>
                    <li>iPhone 12 Mini</li>
                    <li>iPhone 12 Pro</li>
                    <li>iPhone 12 Pro Max</li>
                    <li>iPhone 11</li>
                    <li>iPhone 11 Pro</li>
                    <li>iPhone 11 Pro Max</li>
                    <li>iPhone XS</li>
                    <li>iPhone XS Max</li>
                    <li>iPhone XR</li>
                    <li>iPhone SE (2020)</li>
                    <li>iPhone SE (2022)</li>
                    <li>iPad Air (3rd Generation)</li>
                    <li>iPad Air (4th generation)</li>
                    <li>iPad Pro 11‑inch (1st generation)</li>
                    <li>iPad Pro 11‑inch (2nd generation)</li>
                    <li>iPad Pro 11-inch (3rd generation)</li>
                    <li>iPad Pro 12.9‑inch (3rd generation)</li>
                    <li>iPad Pro 12.9‑inch (4th generation)</li>
                    <li>iPad Pro 12.9-inch (5th generation)</li>
                    <li>iPad (7th generation)</li>
                    <li>iPad (8th generation)</li>
                    <li>iPad (9th generation)</li>
                    <li>iPad Mini (5th generation)</li>
                    <li>iPad Mini (6th generation)</li>
                </ul>
                <p>The following Apple devices DO NOT have eSIM capability:
                </p>
                <ul>
                    <li>iPhone from mainland China.
                    </li>
                    <li>iPhone devices from Hong Kong and Macao (except for iPhone 13 mini, iPhone 12 mini, iPhone SE
                        2020, and iPhone XS).
                    </li>
                    <li><span  style={{fontSize: "0.8575rem"}}>With iPhone 13 Pro Max, iPhone 13 Pro, iPhone 13, and iPhone 13 mini, you can use Dual SIM with either two active eSIMs or a nano-SIM and an eSIM. iPhone 12 models, iPhone 11 models, iPhone XS, iPhone XS Max, and iPhone XR, feature Dual SIM with a nano-SIM and an eSIM.</span>
                    </li>
                </ul>
                <p>Samsung</p>
                <ul>
                    <li>Samsung Galaxy S22 Ultra 5G</li>
                    <li>Samsung Galaxy S22+ 5G</li>
                    <li>Samsung Galaxy S22 5G</li>
                    <li>Samsung Galaxy S21 Ultra 5G</li>
                    <li>Samsung Galaxy S21 5G</li>
                    <li>Samsung Galaxy S21+ 5G</li>
                    <li>Samsung Galaxy S20</li>
                    <li>Samsung Galaxy S20+</li>
                    <li>Samsung Galaxy Z Flip</li>
                    <li>Samsung Galaxy Z Flip3 5G</li>
                    <li>Samsung Galaxy Z Fold3</li>
                    <li>Samsung Galaxy Z Fold2</li>
                    <li>Samsung Galaxy Fold</li>
                    <li>Samsung Galaxy Note 20 Ultra</li>
                    <li>Samsung Galaxy Note 20+</li>
                    <li>Samsung Galaxy Note 20</li>
                </ul>
                <p>The following Samsung devices DO NOT have eSIM capability:
                </p>
                <ul>
                    <li>All Samsung S20 FE/S21 FE models
                    </li>
                    <li>US versions of Samsung S20/S21 and Galaxy Z Flip 5G
                    </li>
                    <li>USA and Hong Kong versions of Samsung Note 20 Ultra and Samsung Galaxy Z Fold 2
                    </li>
                </ul>
                <p>Samsung S21 series devices (with the exemption of FE models) from Canada and USA may have eSIM
                    capability as long as the One UI 4 update is installed. Please get in touch with your carrier or
                    device manufacturer to confirm that your Samsung device is eSIM capable.</p>
                <p>Google Pixel</p>
                <ul>
                    <li>Google Pixel 3 &amp; 3 XL</li>
                    <li>Google Pixel 3a &amp; 3a XL</li>
                    <li>Google Pixel 4, 4a &amp; 4 XL</li>
                    <li>Google Pixel 5</li>
                    <li>Google Pixel 6</li>
                    <li>Google Pixel 6 Pro</li>
                </ul>
                <p>The following Google Pixel devices DO NOT have eSIM capability:
                </p>
                <ul>
                    <li>Pixel 3 models originating from: Australia, Taiwan, and Japan; and those bought with service
                        from any of the US or Canadian carriers other than Sprint and Google Fi.
                    </li>
                    <li>Pixel 3a bought in South East Asia and with Verizon service.</li>
                </ul>
                <p>Huawei</p>
                <ul>
                    <li>Huawei P40</li>
                    <li>Huawei P40 Pro</li>
                    <li>Huawei Mate 40 Pro</li>
                </ul>
                <p>Huawei P40 Pro+ is not compatible with eSIMs.</p>
                <p>Others
                    Nuu Mobile X5
                </p>
                <ul>
                    <li>Gemini PDA 4G+Wi-Fi
                    </li>
                    <li>OPPO Find X3 Pro</li>
                    <li>OPPO Find X5 Pro</li>
                    <li>OPPO Find X5</li>
                    <li>Sony Xperia 10 III Lite
                    </li>
                    <li>Sony Xperia 10 IV
                    </li>
                    <li>Sony Xperia 1 IV
                    </li>
                </ul>
                <p>The following OPPO devices DO NOT have eSIM capability:
                </p>
                <ul>
                    <li>eSIM support depends on carriers and coverage options.&nbsp;<span style={{fontSize: "0.8575rem"}}>Coverage may vary. Known regions that do not support dual SIM cards: Australia (Telstra and Optus), and Japan (KDDI). </span>
                    </li>
                    <li><span style={{fontSize: "0.8575rem"}}>Functions available on the live network depend on the carrier’s network and related services deployment.</span>
                    </li>
                </ul>
            </div>
        </main>
    );
}
